import React from 'react'
import {GoogleMap, InfoWindow, LoadScript, Marker} from '@react-google-maps/api';
import {googleApi} from "../../helpers";

export const GMap = React.memo(() => {
    return (
        <div className={'google-map'}>
            <LoadScript
                googleMapsApiKey={googleApi}
            >
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '100%'}}
                    center={{lat: 50.40946568695337, lng: 30.32635385767126}}
                    zoom={15}
                >
                    <Marker position={{lat: 50.40946568695337, lng: 30.32635385767126}} >
                        <InfoWindow>
                            <div style={{color: 'black', display: 'flex', flexDirection: 'column'}}>
                                <h4 style={{marginBlockStart: '3px', marginBlockEnd: '3px', color: 'black'}}>Виготовлення автомобільних ключів</h4>
                                <a href='tel:+380992203208'>+380992203208</a>
                                <a href={'https://goo.gl/maps/Eke5JFat5GPqx6Fe8'}>Вiдкрити в Google Maps</a>
                            </div>
                        </InfoWindow>
                    </Marker>
                </GoogleMap>
            </LoadScript>
        </div>
    )
});