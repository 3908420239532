import React from "react";
import {Card} from "@blueprintjs/core";

export const ConstInfo = React.memo(() => {
    return (
        <Card
            className={'info-block'}
        >
            <h3 style={{textAlign: 'center'}}>Виготовлення автомобільних ключів з чіпом, брелоком, Smartkey.</h3>
            <p>1. Виготовимо ключі при повній втраті всіх типів (так само є нарізка леза по замку). All key lost.</p>
            <p>2. Виготовимо дублікат ключів із брелоком ціна від 1500 до 3000 грн.</p>
            <p>3. Виготовимо smartkey ціна від 2800 грн</p>
            <p>4. Виготовлення ключів при повній втраті від 3000 грн.</p>
            <h3>Працюємо з усіма марками та типами автомобілів.</h3>
            <h3>За більш детальною інформацією телефонуйте.</h3>
        </Card>
    )
})