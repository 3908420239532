import React from 'react';
import {Header} from "./components/Header";
import {GMap} from "./components/GMap";
import {Home} from "./components/Home";

import { Router } from 'react-router';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function App() {
  return (
      <Router history={history}>
          <div className={'app'}>
              <div className={'content-wrapper'}>
                  <Header />
                  <Home />
                  <GMap />
              </div>
          </div>
      </Router>
  );
}

export default App;
