import React from "react";
import {Card} from "@blueprintjs/core";

export const Header = React.memo(() => {
    return (
        <Card
            className={'info-block header'}
        >
            <h3>Виготовлення автомобільних ключів</h3>
        </Card>
    )
})